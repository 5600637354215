import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import Image from 'src/shared/components/Image';
import FeatureRow from './components/FeatureRow';
import FeatureButtonRow from './components/FeatureButtonRow';
import classNames from 'classnames';

interface Props {
    onGetRowHeights: (values: number[]) => void;
    onGetButtonRowHeight?: (value) => void;
    items: string[];
    overwriteHeights?: number[];
    header: {
        isAltive?: boolean;
        title?: string;
        titleSize?: 'small' | 'large';
    };
    button?: {
        text: string;
        onClick: () => void;
    };
    placeholderHeight?: number;
}

const FeatureCard = (props: Props) => {
    const [heights, setHeights] = useState(props.items.map(() => 0));

    const onGetHeight = (value, i) => {
        const newHeights = [...heights];
        newHeights[i] = value;
        setHeights(newHeights);
    };

    useEffect(() => {
        if (!heights.includes(0) && props.onGetRowHeights) {
            props.onGetRowHeights([...heights]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...heights]);

    return (
        <div className={classNames(styles.container, { [styles.altive]: props.header.isAltive })}>
            {props.header.isAltive && (
                <div className={styles.header}>
                    <Image className={styles.logo} src="/static/images/logo_light.svg" />
                </div>
            )}
            {props.header.title && (
                <div className={styles.header}>
                    <p className={classNames(styles.title, { [styles.small]: props.header.titleSize === 'small' })}>{props.header.title}</p>
                </div>
            )}
            {props.items.map((x, i) => (
                <FeatureRow key={i} content={x} onGetHeight={(value) => onGetHeight(value, i)} height={props.overwriteHeights[i]} />
            ))}
            {props.button && (
                <FeatureButtonRow
                    buttonText={props.button.text}
                    onClick={props.button.onClick}
                    onGetHeight={(value) => props.onGetButtonRowHeight(value)}
                />
            )}
            {props.placeholderHeight !== 0 && <div style={{ width: '100%', height: props.placeholderHeight }} />}
        </div>
    );
};

FeatureCard.defaultProps = {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onGetButtonRowHeight: () => {},
    overwriteHeights: [],
    button: null,
    placeholderHeight: 0,
};

export default FeatureCard;
