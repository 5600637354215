import React, { useEffect, useState } from 'react';
import BaseIcon from 'src/shared/components/BaseIcon';
import FeatureCard from './components/FeatureCard';
import styles from './styles.module.scss';
import { IconTypeValues } from 'src/shared/config/IconType';
import classNames from 'classnames';

interface Props {
    titles: Array<{ text?: string; icon?: IconTypeValues }>;
    card1: {
        header: {
            isAltive?: boolean;
            title?: string;
            titleSize?: 'small' | 'large';
        };
        items: string[];
    };
    card2: {
        header: {
            isAltive?: boolean;
            title?: string;
            titleSize?: 'small' | 'large';
        };
        items: string[];
    };
    button?: {
        text: string;
        onClick: () => void;
    };
}

const ComparisonTable = (props: Props) => {
    const [buttonRowHeight, setButtonRowHeight] = useState(0);
    const [heights, setHeights] = useState(props.titles.map(() => 0));

    const [card1Heights, setCard1Heights] = useState(props.card1.items.map(() => 0));
    const [card2Heights, setCard2Heights] = useState(props.card2.items.map(() => 0));

    useEffect(() => {
        if (!card1Heights.includes(0) && !card2Heights.includes(0)) {
            const combineHeights = card1Heights.map((x, i) => {
                const max = Math.max(x, card2Heights[i]);
                return max;
            });
            setHeights(combineHeights);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...card1Heights, ...card2Heights]);

    return (
        <div className={styles.container}>
            <div className={styles.placeholder} />
            {props.titles.map((x, i) => (
                <div key={x.text} className={classNames(styles.row, { [styles.second]: i % 2 !== 0 })} style={{ height: heights[i] }}>
                    {x.text && x.icon && (
                        <div className={styles.content}>
                            <BaseIcon type={x.icon} color="primary" fontSize="46px" className={styles.icon} />
                            <p className={styles.title}>{x.text}</p>
                        </div>
                    )}
                    <div className={styles.placeholder} />
                </div>
            ))}
            {Boolean(buttonRowHeight) && <div className={styles.row} style={{ height: buttonRowHeight }} />}
            <div className={styles.cards}>
                <FeatureCard
                    items={props.card1.items}
                    button={props.button}
                    overwriteHeights={heights}
                    header={props.card1.header}
                    onGetRowHeights={(values) => setCard1Heights(values)}
                    onGetButtonRowHeight={(value) => setButtonRowHeight(value)}
                />
                <FeatureCard
                    items={props.card2.items}
                    overwriteHeights={heights}
                    header={props.card2.header}
                    onGetRowHeights={(values) => setCard2Heights(values)}
                    placeholderHeight={buttonRowHeight}
                />
            </div>
        </div>
    );
};

ComparisonTable.defaultProps = {
    button: null,
};

export default ComparisonTable;
