import React from 'react';
import Image from 'src/shared/components/Image';
import styles from './styles.module.scss';
import RoundedButton from 'src/shared/components/RoundedButton';
import { IconTypeValues } from 'src/shared/config/IconType';
import classNames from 'classnames';

interface Props {
    titles: Array<{ text?: string; icon?: IconTypeValues }>;
    card1: {
        header: {
            isAltive?: boolean;
            title?: string;
        };
        items: string[];
    };
    card2: {
        header: {
            isAltive?: boolean;
            title?: string;
        };
        items: string[];
    };
    button?: {
        text: string;
        onClick: () => void;
    };
}

const ResponsiveComparisonTable = (props: Props) => {
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={classNames(styles.column, { [styles.altive]: props.card1.header.isAltive })}>
                    {props.card1.header.isAltive && <Image className={styles.logo} src="/static/images/logo_light.svg" />}
                    {props.card1.header.title && <p className={styles.title}>{props.card1.header.title}</p>}
                </div>
                <div className={styles.column}>{props.card2.header.title && <p className={styles.title}>{props.card2.header.title}</p>}</div>
            </div>
            {props.titles.map((x, i) => (
                <div key={x.text} className={styles.section}>
                    <p className={styles.title}>{x.text}</p>
                    <div className={styles.row}>
                        <div className={styles.column}>
                            {/* eslint-disable-next-line react/no-danger */}
                            <p className={styles.content} dangerouslySetInnerHTML={{ __html: props.card1.items[i] }} />
                        </div>
                        <div className={styles.column}>
                            {/* eslint-disable-next-line react/no-danger */}
                            <p className={styles.content} dangerouslySetInnerHTML={{ __html: props.card2.items[i] }} />
                        </div>
                    </div>
                </div>
            ))}
            {props.button && (
                <RoundedButton className={styles.contactButton} type="tertiary-on-dark" onClick={props.button.onClick}>
                    {props.button.text}
                </RoundedButton>
            )}
        </div>
    );
};

ResponsiveComparisonTable.defaultProps = {
    button: null,
};

export default ResponsiveComparisonTable;
