import React, { useEffect } from 'react';
import useMeasure from 'src/shared/hooks/useMeasure';
import styles from './styles.module.scss';

interface Props {
    content: string;
    height?: number;
    onGetHeight: (value: number) => void;
}

const FeatureRow = (props: Props) => {
    const [bind, { height }] = useMeasure();

    useEffect(() => {
        if (height) {
            props.onGetHeight(height);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [height]);

    return (
        <div className={styles.row} {...bind} style={props.height ? { height: props.height } : {}}>
            <p
                className={styles.content}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                    __html: props.content,
                }}
            />
        </div>
    );
};

FeatureRow.defaultProps = {
    height: 0,
};

export default FeatureRow;
