import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { IconTypeValues } from 'src/shared/config/IconType';

interface Props {
    type: IconTypeValues;
    className?: string;
    fontSize?: string;
    color?: 'primary' | 'theme' | 'white';
}

const BaseIcon = (props: Props) => {
    const iconStyle: any = { fontSize: props.fontSize };
    const iconClassNames = classNames(styles.icon, props.type, {
        [props.className]: props.className,
        [styles[props.color]]: props.color,
    });
    return <i className={iconClassNames} style={iconStyle} />;
};

BaseIcon.defaultProps = {
    className: '',
    fontSize: '18px',
    color: '',
};

export default BaseIcon;
