import React, { useEffect } from 'react';
import RoundedButton from 'src/shared/components/RoundedButton';
import useMeasure from 'src/shared/hooks/useMeasure';
import styles from './styles.module.scss';

interface Props {
    buttonText: string;
    onGetHeight: (value: number) => void;
    onClick?: () => void;
}

const FeatureButtonRow = (props: Props) => {
    const [bind, { height }] = useMeasure();

    useEffect(() => {
        if (height) {
            props.onGetHeight(height);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [height]);

    return (
        <div className={styles.row} {...bind}>
            <div className={styles.control}>
                <RoundedButton className={styles.contactButton} type="tertiary-on-dark" onClick={props.onClick}>
                    {props.buttonText}
                </RoundedButton>
            </div>
        </div>
    );
};

FeatureButtonRow.defaultProps = {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onClick: () => {},
};

export default FeatureButtonRow;
