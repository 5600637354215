/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';

interface Props {
    children: React.ReactNode;
    containerClassName?: string;
    className?: string;
    isLarge?: boolean;
}

const MarketingSection = forwardRef((props: Props, ref: any) => {
    const containerClassNames = classNames(styles.container, props.containerClassName);
    const contentClassNames = classNames(styles.content, props.className, { [styles.large]: props.isLarge });
    return (
        <div className={containerClassNames} ref={ref}>
            <div className={contentClassNames}>{props.children}</div>
        </div>
    );
});

MarketingSection.defaultProps = {
    containerClassName: '',
    className: '',
    isLarge: false,
};

export default MarketingSection;
